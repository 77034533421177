import type { LocationQuery } from 'vue-router'

/**
 * Generates a path object from a slug and query that represents the an RV rentals page.
 */
export const generateRvPath = (slug: string, query: LocationQuery) => {
  return {
    name: 'rv-rental',
    params: { alias: slug },
    query,
  }
}
