<template>
  <component
    :is="openSameWindow && !forceReload && !isStillLoading ? 'nuxt-link' : 'a'"
    ref="card"
    v-element-visibility="scrolledIntoView"
    :href="(!openSameWindow || forceReload) && !isStillLoading ? urlToListing : null"
    :to="openSameWindow && !forceReload && !isStillLoading ? urlToListing : null"
    :title="rv.RVName"
    :target="linkTarget"
    :class="['rv-card', { pulse: isStillLoading }]"
    :data-testid="`rv-card-${index}`"
    @click="rvClicked"
  >
    <div class="rv-images">
      <Transition name="fade">
        <div
          v-if="isStillLoading"
          key="loader"
          class="skel"
        />
        <LazyAppCarouselImages
          v-else
          key="content"
          :images="photos"
          :img-width="450"
          :img-height="300"
          :resolution="imageResolution"
          :preload="Boolean(photos.length && index === 0)"
          :eager-loading="index === 0 && isVisible"
          @click="onCarouselClick"
          @slide-changed="updateImageIndex"
          @track-queued-actions="trackQueuedActions"
        />
      </Transition>

      <Transition name="fade">
        <div v-if="!isLoading && shouldShowBadge">
          <LazyCardRvBadge :badge="badge" />
        </div>
      </Transition>

      <Transition name="fade">
        <LazyFavouritesButton
          v-if="!isStillLoading"
          icon-button-size="md"
          :rv-id="String(rv.Id)"
        />
      </Transition>
    </div>

    <div class="rv-info">
      <div class="rv-header">
        <Transition name="fade">
          <div
            v-if="isStillLoading"
            key="loader"
            class="skel"
          />
          <div
            v-else
            v-element-visibility="visibilityChanged"
            class="rv-title"
          >
            <div class="rv-name">
              {{ rv.RVName }}
            </div>
            <LazyZRating
              v-if="rv.AverageRating"
              :value="formatWithPrecision(rv.AverageRating)"
              bold
              class="d-flex align-items-center ml-2"
            />
          </div>
        </Transition>
      </div>

      <div class="rv-meta">
        <Transition name="fade">
          <p
            v-if="isStillLoading"
            key="loader"
            class="skel skel1 w-2/3"
          />
          <p
            v-else
            key="content"
          >
            {{ t('sleeps') }} {{ rv.Guests }} &bull; {{ $t(useRvType(rv.RVType)) }}
          </p>
        </Transition>

        <Transition name="fade">
          <p
            v-if="isStillLoading"
            key="loader"
            class="skel skel2 w-4/5"
          />
          <p
            v-else
            key="content"
          >
            {{ rv.City }}, {{ lookupStateToIsoCode(rv.State)
            }}<template v-if="showDistanceAway">
              ({{ t('away', { distance: distanceAway }) }})
            </template>
          </p>
        </Transition>
      </div>

      <div
        v-if="showDescription"
        class="rv-description"
      >
        <Transition name="fade">
          <p
            v-if="isStillLoading"
            key="loader"
            class="skel"
          />
          <p
            v-else
            key="content"
          >
            {{ rv.Description }}
          </p>
        </Transition>
      </div>

      <div class="rv-price">
        <Transition name="fade">
          <div
            v-if="isStillLoading"
            key="loader"
            class="skel w-2/3"
          />
          <div
            v-else
            key="content"
            class="rv-prices"
          >
            <fa
              v-if="rv.InstabookOwnerOptedIn"
              :icon="['fas', 'bolt']"
              class="instabook"
            />

            <span
              v-if="rv.DiscountPercent > 0"
              data-testid="discount-display"
              class="pre-discount-price"
            >{{
              priceDisplay({
                value: rv.PreDiscountedAverageNightlyPrice,
                countryCode: rv.Country,
                showCurrencyCode: false,
                round: true,
                internationalPricing: true,
              })
            }}</span>

            <span
              data-testid="nightly-price"
              class="nightly-price"
            >
              {{
                priceDisplay({
                  value: Math.max(rv.DiscountedAverageNightlyPrice, 0) || rv.DefaultPrice,
                  countryCode: rv.Country,
                  showCurrencyCode: true,
                  round: true,
                  internationalPricing: true,
                })
              }}
              <span class="per">/{{ t('night') }}</span>
            </span>
          </div>
        </Transition>
      </div>

      <div
        v-if="hasTags"
        class="rv-tags"
      >
        <TransitionGroup name="fade">
          <span
            v-if="isStillLoading"
            key="loader"
            class="skel w-1/3"
          />
          <template v-else>
            <CardRvTag
              v-for="tag in tags"
              :key="tag"
              :tag="tag"
            />
          </template>
        </TransitionGroup>
      </div>
    </div>
  </component>
</template>

<script>
import { vElementVisibility } from '@vueuse/components'
import { ImageResolution } from '~/lib/enums'
import {
  getOriginalNightlyRate,
  getRvMeasurementUnit,
  getSmartNightlyRate,
  getBadges,
  getTags,
  isDeliveryOnly,
  isInstantBook,
  isSuperhost,
} from '~/lib/rvs'
import { trackListingClicked, trackListingSetViewed } from '~/lib/tracking'
import { searchParametersToSearchQuery } from '~/lib/search/parameters'
import useRvType from '~/lib/useRvType'
import useToDistance from '~/lib/useToDistance'
import { IMAGE_QUALITY } from '~/constants/image'

export default {
  name: 'RvCard',
  directives: { elementVisibility: vElementVisibility },

  props: {
    /**
     * The actual index of the RV in the list. This is used to emit back to the
     * list in order to tell the list when to load the next set of RVs.
     */
    index: {
      type: Number,
      default: 0,
    },

    rv: {
      type: Object,
      default: () => ({}),
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    cta: {
      type: String,
      default: null,
    },

    searchRequestId: {
      type: String,
      required: true,
    },

    visibilityThreshold: {
      type: Number,
      default: 0.5,
    },

    visibilityThrottle: {
      type: Number,
      default: 500,
    },

    coords: {
      type: [Array, Boolean],
      default: false,
    },

    destinationName: {
      type: [String, Boolean],
      default: false,
    },

    variant: {
      type: String,
      default: 'gallery',
    },

    trackVisiblity: {
      type: Boolean,
      default: false,
    },

    showFeatured: {
      type: Boolean,
      default: false,
    },

    showDescription: {
      type: Boolean,
      default: false,
    },

    /**
     * Determines wether or not a card should load when it scrolls into view
     * (default), or imediately (useful for SEO).
     */
    loadWhenVisible: {
      type: Boolean,
      default: true,
    },

    pageSize: {
      type: Number,
      default: 10,
    },

    listPageNumber: {
      type: Number,
      default: 1,
    },

    dates: {
      type: Object,
      default: null,
    },
  },

  emits: ['visible:rv', 'scrolled-into-view'],

  setup() {
    const { t } = useI18n({
      useScope: 'local',
    })
    const { priceDisplay } = usePrice()
    const { isRvFavourited } = useFavouriteRVs()
    const { routeBaseName } = useBaseName()
    const { isWebView } = usePlatform()
    const { getImageUrl } = useImageUrl()

    return {
      t,
      priceDisplay,
      isRvFavourited,
      routeBaseName,
      isWebView,
      useRvUrl,
      getImageUrl,
    }
  },

  data() {
    return {
      imageIndex: 0,

      /**
       * Tracks wether or not the card is scrolled into view.
       */
      isVisible: false,
      hasBeenScrolledIntoView: false,
      hasBeenTracked: false,
    }
  },

  computed: {
    RvTags() {
      return RvTags
    },

    datesText() {
      if (!this.dates) return

      const start = this.$moment(this.dates.start)
      const end = this.$moment(this.dates.end)

      const sameMonth = start.month() === end.month()

      const startDateFormat = this.$t(`dates.range.start.${sameMonth ? 'short' : 'long'}`)
      const endDateFormat = this.$t(`dates.range.end.${sameMonth ? 'short' : 'long'}`)

      return `${start.format(startDateFormat)} — ${end.format(endDateFormat)}`
    },

    /**
     * The card can be in 2 loading "states":
     * 1. The data is actually still loading from the API.
     * 2. The card hasn't scrolled into view yet (if enabled).
     */
    isStillLoading() {
      return this.isLoading || !this.isVisible
    },

    isFeatured() {
      return this.cta === 'featured'
    },

    showDistance() {
      return Boolean(
        (this.destinationName && this.destinationName !== this.rv.City)
        || this.$search.parameters.location?.city?.long_name,
      )
    },

    distanceUnit() {
      return getRvMeasurementUnit(this.rv)
    },

    distanceAway() {
      const lat = this.coords[0] || this.$search.parameters.location?.center?.lat
      const lng = this.coords[1] || this.$search.parameters.location?.center?.lng

      if (!(lat && lng)) {
        return null
      }

      return useToDistance({
        locale: this.$i18n,
        lat1: lat,
        lon1: lng,
        lat2: this.rv.Latitude,
        lon2: this.rv.Longitude,
        unit: this.distanceUnit,
        showUnit: true,
      })
    },

    urlToListing() {
      const query = searchParametersToSearchQuery(this.$search.parameters)
      return this.useRvUrl(this.rv.AliasName, query)
    },

    photos() {
      if (!this.rv?.Photos) {
        return []
      }

      const photos = this.rv.Photos.map((photo) => ({
        path: photo.Path,
        description: this.rv.RVName,
      }))

      return photos
    },

    imageResolution() {
      return ImageResolution.MEDIUM
    },

    openSameWindow() {
      return Boolean(this.$route.meta?.layout === 'minimal' || this.isWebView || this.$device.isMobile)
    },

    forceReload() {
      return this.routeBaseName === 'rv-search'
    },

    linkTarget() {
      if (!this.openSameWindow) {
        return '_blank'
      }
      return null
    },

    showDistanceAway() {
      return this.cta !== 'map' && this.showDistance && this.distanceAway !== null
    },

    isSuperhost() {
      return isSuperhost(this.rv)
    },

    isInstantBook() {
      return isInstantBook(this.rv)
    },

    isDeliveryOnly() {
      return isDeliveryOnly(this.rv)
    },

    badges() {
      const badges = getBadges(this.rv)
      if (!badges) return
      return badges
    },

    badge() {
      return this.badges?.priority
    },

    tags() {
      return getTags(this.rv, this.isFeatured)
    },

    hasTags() {
      return Boolean(this.tags?.length)
    },

    shouldShowBadge() {
      return Boolean(this.badges)
    },
  },

  created() {
    /**
     * Determine wether or not we want to only show data when the card is
     * scrolled into view.
     */
    this.isVisible = !this.loadWhenVisible
  },

  methods: {
    useRvType,

    /**
     * Triggers when the card has scrolled into (or out of) view.
     */
    scrolledIntoView(visible) {
      /**
       * If we don't want to lazy load content, or when the card has scrolled
       * out of view, or when it has already been triggered, exit early.
       *
       * Otherwise fire the 'scrolled-into-view' event and track it locally.
       */
      if (!this.loadWhenVisible || !visible || this.hasBeenScrolledIntoView) return

      this.$emit('scrolled-into-view', this.index)

      this.isVisible = visible
      this.hasBeenScrolledIntoView = true
    },

    onCarouselClick(e) {
      // Skip track at photos navigation
      if (e.target.closest('.glide__bullet') || e.target.closest('.glide__arrow')) {
        e.stopPropagation()
        e.preventDefault()
      }
    },

    updateImageIndex(index) {
      if (typeof index === 'number') {
        this.imageIndex = index
      }
    },

    trackClick() {
      const me = this.$refs.card
      const parent = me.parentNode

      const listIndex = [...parent.children].findIndex((el) => el === me)
      const position = listIndex + 1
      const columnCount = window.getComputedStyle(parent).getPropertyValue('grid-template-columns').split(' ').length
      const listRowNumber = Math.floor(listIndex / columnCount) + 1
      const listColumnNumber = (listIndex % columnCount) + 1

      trackListingClicked({
        cta: this.cta || this.routeBaseName,
        distanceShown: this.showDistance,
        hasDiscount: this.rv?.DiscountPercent > 0 || undefined,
        hasFlexibleDates: undefined,
        isMapOpen: document.getElementsByClassName('map-wrapper').length > 0,
        isShortStay: this.rv.IsShortStay ?? false,
        isSuperhost: this.rv.IsSuperHostActive ?? false,
        listCardVariant: this.variant,
        listColumnNumber,
        listRowNumber,
        pageSource: this.routeBaseName,
        photoPath: this.photos[this.imageIndex]?.path || '',
        photoPosition: this.imageIndex + 1,
        rv: {
          heroImage: this.getImageUrl({
            path: this.rv.Photos[0].Path,
            quality: IMAGE_QUALITY.medium,
          }),
          isFavourite: Boolean(this.isRvFavourited(this.rv.Id)),
          ...this.rv,
        },
        position,
        rvType: this.rv.RVType,
      })
    },

    rvClicked() {
      if (this.isStillLoading) {
        return
      }

      this.trackClick()
      this.setRvDetailsPageReferrerAndCta()

      if (this.cta === 'map') {
        return
      }
    },

    visibilityChanged(visible) {
      if (!visible) return

      if (!this.trackVisiblity) {
        this.$emit('visible:rv', { rv: this.rv, cta: this.cta, requestId: this.searchRequestId })
        return
      }

      trackListingSetViewed({
        params: {
          cta: this.cta,
          listingPageNumber: this.listPageNumber,
          listingPageSize: this.pageSize,
          searchResults: [
            {
              heroImage: this.getImageUrl({
                path: this.rv.Photos[0].Path,
                quality: IMAGE_QUALITY.medium,
              }),
              rvUrl: useAbsoluteRvUrl(this.rv.AliasName),
              isFavourite: Boolean(this.isRvFavourited(this.rv.Id)),
              nightlyRate: getOriginalNightlyRate(this.rv),
              smartNightlyRate: getSmartNightlyRate(this.rv),
              ...this.rv,
            },
          ],
          pageSource: this.routeBaseName,
        },
      })
    },

    setRvDetailsPageReferrerAndCta() {
      if (this.cta) {
        // Add rvId to the key, so it's unique to each RV.
        // Localstorage was used instead of sessionStorage, as sessionStorage didn't work when opening certain links in a new tab
        window.localStorage.setItem(`rvDetailsPageCta_${this.rv.Id}`, this.cta)
      }

      // Store the current route in localStorage. The RV Details page is opened in a new tab, so context does not contain the route
      window.localStorage.setItem(`rvDetailsPageCtaReferrer_${this.rv.Id}`, this.getRouteBaseName(this.$route) || '')
    },

    trackQueuedActions() {
      /*
        This event is being commented out until we need it again
        Reference ticket: RVZ-12612
      */
      // if (this.rv && photos.length) {
      //   trackPhotoCarouselUpdateSuccessful({
      //       rvId: this.rv.Id,
      //       pageSource: this.getRouteBaseName(),
      //       photoListSize: this.photos.length,
      //       requestId: this.searchRequestId || undefined,
      //       actionType: this.$device.isDesktop ? 'click' : 'scroll',
      //       photos: photos
      //     })
      // }
    },
  },
}
</script>

<style lang="scss" scoped>
.pulse {
  cursor: wait;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
}

.skel {
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: inline-block;
  border-radius: 0.25rem;
  background-color: getColor('gray-100');
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: calc(100% * (1 / 3));
}

.w-2\/3 {
  width: calc(100% * (2 / 3));
}

.w-4\/5 {
  width: calc(100% * (4 / 5));
}

.rv-images {
  position: relative;
  width: 100%;
  height: 0;
  display: block;
  padding-bottom: calc(2 / 3 * 100%);
  border-radius: 1rem;
  overflow: hidden;
  margin-bottom: 0.75rem;

  :deep(.glide__slide) {
    border-radius: 1rem;
    overflow: hidden;
  }
}

.zbadge {
  &.superhost,
  &.instantbook {
    border-radius: 1rem;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    z-index: 2;
    font-size: 0.75rem;
    padding: 0.1875rem 0.625rem;
    svg {
      font-size: 0.875rem;
      vertical-align: -0.1875rem;
    }
  }

  &.instantbook {
    color: getColor('primary-500');
  }

  &.superhost {
    color: getColor('highlight-500');
  }
}

.rv-card {
  position: relative;
  overflow: hidden;
  color: getColor('primary-500');

  &:hover {
    text-decoration: none;
  }
}

.rv-image {
  border-radius: 1rem;
  overflow: hidden;

  :deep(img) {
    border-radius: 1rem;
  }
}

.rv-header {
  position: relative;
  height: 1.5rem;
  margin-bottom: 0.25rem;
}

.rv-title {
  display: flex;
  align-items: flex-start;
}

.badge.rare-find {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  border: none;
  color: getColor('highlight-500');
  background-color: getColor('highlight-50');
  padding: 0.2rem 0.5rem;
  @include semi-bold;
  font-size: 0.75rem;
}

.rv-meta {
  position: relative;
  height: 40px;
  margin-bottom: 0.75rem;
}

.rv-meta,
.rv-meta-guests,
.rv-meta-city,
.rv-price {
  position: relative;
  height: 40px;

  p {
    margin: 0;
  }
}

.rv-meta-city p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.rv-price {
  height: 1.5rem;
  margin-bottom: 0.75rem;
}

.rv-tags {
  margin-top: 1rem;
  position: relative;
  height: 1.625rem;
  display: flex;
  gap: 0.5rem;
}

.rv-info {
  position: relative;
  margin-top: 0.75rem;
  color: getColor('primary-500');

  &:hover {
    text-decoration: none;
  }

  p {
    margin: 0;
  }
}

.rv-name {
  @include strong-1;
  position: relative;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 1.5rem;
  margin: 0;
}

.rv-meta {
  @include caption;
  position: relative;
  color: getColor('primary-350');
  margin-bottom: 0.75rem;
}

.nightly-price {
  @include semi-bold;
  display: flex;
  font-size: 1rem;
  align-items: center;

  @include media-max-size(large) {
    @include rv-card-price;
  }

  .night {
    font-size: 0.75rem;
    text-transform: lowercase;
  }
}

.pre-discount-price {
  @include body-1;
  margin-right: 0.5rem;

  text-decoration: line-through;
  color: getColor('primary-350');
}

@include media-max-size(medium) {
  .nightly-price,
  .pre-discount-price {
    font-size: 1rem;
  }
}

.instabook {
  color: getColor('highlight-500');
  margin-right: 0.2rem;
}

:deep(.favourite-button) {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 2;

  > .zbtn {
    background-color: rgba(255, 255, 255, 0.8) !important;

    &:hover {
      background-color: #fff !important;
    }

    svg {
      color: getColor('primary-500');
      font-size: 1rem;
    }

    @include media-min-size(medium) {
      width: 1.5rem !important;
      height: 1.5rem !important;

      svg {
        font-size: 0.75rem;
      }
    }
  }
}

.rv-prices {
  display: flex;
  align-items: center;
}

.per {
  font-size: 0.75rem;
  font-weight: normal;

  @include media-max-size(medium) {
    @include rv-card-price;
  }
}

.city {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rv-description {
  position: relative;
  height: 3.75rem;
  margin-bottom: 0.75rem;
}
.rv-description {
  max-width: 100%;
  > p {
    margin: 0;
    color: getColor('primary-500');
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 0.875rem;
    @include regular-weight;
    line-height: 1.25rem;
  }
}

.skel1,
.skel2 {
  height: 20px;
}

.skel2 {
  top: 20px;
}
</style>

<i18n lang="json">
{
  "en": {
    "away": "{distance} away",
    "night": "night",
    "sleeps": "Sleeps",
    "featured": "Featured",
    "rareFind": "Rare Find",
    "superhost": "Superhost",
    "instantbook": "Instant Book"
  },
  "fr": {
    "away": "à {distance}",
    "night": "nuit",
    "sleeps": "Couche",
    "featured": "En vedette",
    "rareFind": "Trouvaille rare",
    "superhost": "Superhôtes",
    "instantbook": "Réservation Instantanée"
  }
}
</i18n>
